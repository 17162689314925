@charset "UTF-8";

:root {
  --main_primary: #005650;
  --main_secondary: #16d7c0;
  --main_secondary_dark: #16d7c0;
  --main_text: #3c3c3c;
  --main_light_gray: #989898;
  --main_body_text_color: #4b4b4b;
  --color_bs_pink: #d63384;
  --color_bs_red: #dc3545;
  --color_bs_orange: #fd7e14;
  --color_bs_yellow: #ffc107;
  --color_bs_green: #198754;
  --color_bs_teal: #20c997;
  --color_bs_dark_teal: #1aac80;
  --color_bs_cyan: #16d7c0;
  --color_bs_white: #fff;
  --color_bs_gray: #6c757d;
  --color_bs_gray_dark: #343a40;
  --text_hindi: #e1aa03;
  --main_op_back: #c1d0e1;
  --main_black: #1a1a1a;
  --main_green_color: #5BC2AB;
  --main_claim_back: #e4eef9;
  --ckyc_color: #ffc10730;
  --main_light_green: #47D990;
  --color_bs_purpal: #fcefef;
  --main_text_black: #111;
  --color_border_purpal: #fcefef;
  --main_secondary-bg: #16d7c0;
  --main_light_sky_bg: #2cb2b31a;
  --color_bs_red_light: #dc354639
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-ExtraBold.eot);
  src: url(../fonts/Poppins-ExtraBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraBold.woff2) format('woff2'), url(../fonts/Poppins-ExtraBold.woff) format('woff'), url(../fonts/Poppins-ExtraBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Black.eot);
  src: url(../fonts/Poppins-Black.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Black.woff2) format('woff2'), url(../fonts/Poppins-Black.woff) format('woff'), url(../fonts/Poppins-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Bold.eot);
  src: url(../fonts/Poppins-Bold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Bold.woff2) format('woff2'), url(../fonts/Poppins-Bold.woff) format('woff'), url(../fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-ExtraLight.eot);
  src: url(../fonts/Poppins-ExtraLight.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-ExtraLight.woff2) format('woff2'), url(../fonts/Poppins-ExtraLight.woff) format('woff'), url(../fonts/Poppins-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Medium.eot);
  src: url(../fonts/Poppins-Medium.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Medium.woff2) format('woff2'), url(../fonts/Poppins-Medium.woff) format('woff'), url(../fonts/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Regular.eot);
  src: url(../fonts/Poppins-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Regular.woff2) format('woff2'), url(../fonts/Poppins-Regular.woff) format('woff'), url(../fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Light.eot);
  src: url(../fonts/Poppins-Light.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Light.woff2) format('woff2'), url(../fonts/Poppins-Light.woff) format('woff'), url(../fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-Thin.eot);
  src: url(../fonts/Poppins-Thin.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-Thin.woff2) format('woff2'), url(../fonts/Poppins-Thin.woff) format('woff'), url(../fonts/Poppins-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: poppins;
  src: url(../fonts/Poppins-SemiBold.eot);
  src: url(../fonts/Poppins-SemiBold.eot?#iefix) format('embedded-opentype'), url(../fonts/Poppins-SemiBold.woff2) format('woff2'), url(../fonts/Poppins-SemiBold.woff) format('woff'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap
}


html {
  font-family: poppins, sans-serif !important
}

body {
  font-family: poppins !important;
  line-height: 24px;
  letter-spacing: .4px;
  color: #005650;
  background-color: #fff;
  font-size: 15px;
  color: #4b4b4b;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  padding: 0 !important
}

a:focus,
a:focus-visible,
button:focus,
button:focus-visible,
input:focus,
input:focus-visible {
  outline: 0 !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

.form-select:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none
}

li,
ul {
  margin: 0;
  padding: 0;
  list-style: none
}

a,
a:hover {
  text-decoration: none !important;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -ms-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out
}

img {
  width: auto;
  max-width: 100%
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px
}

::-webkit-scrollbar-track {
  background: #f1f1f1
}

::-webkit-scrollbar-thumb {
  background: #005650
}

::-webkit-scrollbar-thumb:hover {
  background: #005650
}

.overflow_hiddenn {
  overflow: hidden;
}


.error_section {
  padding: 100px 15px;
  text-align: center;
}

.error_section .error {
  font-size: 150px;
  color: #008B62;
  text-shadow:
    1px 1px 1px #00593E,
    2px 2px 1px #00593E,
    3px 3px 1px #00593E,
    4px 4px 1px #00593E,
    5px 5px 1px #00593E,
    6px 6px 1px #00593E,
    7px 7px 1px #00593E,
    8px 8px 1px #00593E,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.error_section .page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.error_section .back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #222;
  border-radius: 6px;
}

.error_section .back-home:hover {
  background: #222;
  color: #ddd;
}


/* ********************************************Custom_loder_css_end***************************************** */

.table_csutome_content {
  padding: 15px 5px;
  box-shadow: inherit !important;
  margin-top: 0px !important;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.table_csutome_content p,
.table_csutome_content span {
  margin: 0;
  font-size: 12px !important;
}

.timeline-item {
  background: #fff;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  width: auto;
  min-height: 98px;
  margin-bottom: 25px;
}

.animated-background {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.outlined .background-masker {
  border: 1px solid #ddd;
}

.outlined:hover .background-masker {
  border: none;
}

.outlined:hover .background-masker:hover {
  border: 1px solid #ccc;
  z-index: 1;
}

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 590px;
  right: 0;
}

.background-masker.subheader-right {
  left: 490px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 550px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}


@media (max-width: 767px) {

  .table_csutome_content p,
  .table_csutome_content span {
    margin: 0;
    font-size: 10px !important;
  }
}

/* ********************************************Custom_loder_css_end***************************************** */


.main_custom_popup {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  background: #28354f5c;
  left: 0;
  top: 0;
  padding: 20px;
  margin: auto;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.main_custom_popup .main_custom_popup_inner {
  border: 1px solid #ddd;
  border-radius: 10px;
  height: auto;
  position: relative;
  background: #fff;
  width: 350px;
  margin: auto;
  margin-top: 0;
  box-shadow: 0px 0px 10px 0px var(--main_primary)6b;
  overflow: hidden;
}

#login_popup_main.main_custom_popup .main_custom_popup_inner {
  width: 100%;
  max-width: 450px;
 background-color: #fff;
  margin: auto;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop {
  position: absolute;
  right: 21px;
  top: 10px;
  cursor: pointer;
}

.main_custom_popup .main_custom_popup_inner .close_btn_pop img {
  width: 15px !important;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_header {
  font-size: 20px;
  font-weight: 600;
  color: #005650;
  padding: 11px 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body {
  padding: 5px 20px 15px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 320px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body::-webkit-scrollbar {
  width: 0px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data {
  padding-bottom: 30px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .img_box_login img {
  width: 180px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .image_text_box .login_text_title {
  font-size: 22px;
  font-weight: 600;
  color: #005650;
  line-height: 31px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details a {
  background: #005650;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 15px;
  line-height: 18px;
  box-shadow: 0px 0px 10px 0px #00bcd48c;
  margin: 0 5px;
  margin-bottom: 15px;
}

.successMessage_card {
  text-align: center;
  border: 1px solid var(--main_claim_back);
  background: #00a0e30d;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.successMessage_card h1 {
  color: #111;
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 600;
  margin: 10px 0px 5px 0px;
}

.success_inner_card .checkmark {
  font-size: 24px;
  color: #fff;
  background: #28a745;
  min-width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  border-radius: 10px;
}

.successMessage_card p {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
}



/* ******************************************main_header_css_start**************************************** */

.main_header {
  position: relative;
  z-index: 9;
  background-color: #fff;
}

.main_header .header_static {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.main_header .header_static .logo_section {
  width: 140px;
}

.main_header .header_static .logo_section img {
  width: 90px;
}

.main_header .header_static .main_menu_inner {
  width: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

.main_header .header_static .main_menu_inner .main_menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.main_header .header_static .main_menu_inner .main_menu nav ul {
  display: flex;
  margin-bottom: 0px;
  line-height: 29px;
}

.main_header .header_static .main_menu_inner .main_menu nav ul li.login_dropdown {
  position: relative;
}

.main_header .header_static .main_menu_inner .main_menu nav ul li.login_dropdown>a img {
  width: 23px;
  margin-right: 5px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a {
  display: block;
  padding: 7px 15px;
  color: #111;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 0;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight {
  border: 1px solid #005650;
  border-radius: 5px;
  height: 35px;
  line-height: 30px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a.highlight:hover {
  background-color: #005650;
  color: #fff;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li>a small.down_icon {
  border-bottom: 2px solid #111;
  border-right: 2px solid #111;
  width: 8px;
  height: 8px;
  display: inline-block;
  transform: rotate(45deg);
  position: relative;
  top: -3px;
  margin-left: 4px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a small.down_icon {
  border-color: #005650;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover>a {
  color: #005650;
}

.main_header .header_static .main_menu_inner .main_menu .login_box {
  margin-left: 10px;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button {
  width: 70px;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  background-image: linear-gradient(110deg, #005650 0%, #005650 100%);
  border: none;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  z-index: 1;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: #005650;
  border-radius: 5px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5), 7px 7px 20px 0px rgba(0, 0, 0, .1), 4px 4px 5px 0px rgba(0, 0, 0, .1);
  transition: all 0.3s ease;
}

.main_header .header_static .main_menu_inner .main_menu .login_box button:hover:after {
  left: 0;
  width: 100%;
  border-radius: 5px;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu {
  position: absolute;
  min-width: 170px;
  padding: 10px 0px;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  left: inherit;
  right: 0;
  z-index: 9;
  top: 45px;
  height: 0px;
  display: none;
  visibility: hidden;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li:hover .sub_menu {
  display: block;
  height: auto;
  visibility: visible;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button,
.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a {
  color: #111;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  display: block;
  text-transform: capitalize;
  font-weight: 400;
  padding: 7px 10px;
  border: none;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li button:hover .main_header .header_static .main_menu_inner .main_menu nav>ul>li ul.sub_menu li a:hover {
  color: #009687 !important;
}

.main_header .header_static .main_menu_inner #menu_open_btn {
  display: none;
}

.main_header .menubar_row .mobile_menu_open {
  padding: 0;
  width: 20px;
  border: none;
  background-color: transparent;
  display: none;
}

/* ******************************************main_header_css_End**************************************** */
.main_footer {
  background-color: #005650;
}

.main_footer .footer_menu_row {
  background-color: #005650;
  padding: 15px;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.main_footer .footer_menu_row2 {
  padding: 15px 0px;
  display: flex;
  justify-content: center;
}

.main_footer .footer_menu_row2 ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0px;
  flex-wrap: wrap;
}

.main_footer .footer_menu_row2 ul li {
  margin-right: 30px;
}

.main_footer .footer_menu_row2 ul li:last-child {
  margin-right: 0px;
}

.main_footer .footer_menu_row .footer_menu_item ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0px;
  flex-wrap: wrap;
}

.main_footer .footer_menu_row2 ul li a,
.main_footer .footer_menu_row .footer_menu_item ul li a {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
  padding: 0 15px;
}

.main_footer .footer_menu_row2 ul li a:hover,
.main_footer .footer_menu_row .footer_menu_item ul li a:hover {
  color: var(--color_bs_white);
}

.main_footer .footer_menu_row .footer_menu_item ul li ul {
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 20px;
}

.main_footer .footer_menu_row .footer_menu_item ul li ul li {
  text-align: l;
}

.main_footer .footer_menu_row .footer_menu_item ul li ul li a {
  text-align: left;
  font-size: 12px;
  padding: 7px 0;
  display: block;
}

.main_footer .footer_full_black {
  background-color: #005650;
}

.main_footer .footer_full_black .get_in_touch {
  padding-top: 25px;
  position: relative;
  margin-bottom: 20px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_title {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background-color: #005650;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  width: 160px;
  margin: 0 auto;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row {
  display: flex;
  border-top: 1px solid #fff;
  border-radius: 0;
  padding: 15px 0px 0px 0px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row {
  width: 300px;
  display: flex;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span {
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p {
  font-size: 14px;
  color: #fff !important;
  margin-bottom: 0;
  letter-spacing: 0;
  background: none !important;
  border: none;
  padding: 0px !important;
  font-weight: 300;
  line-height: 18px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
  width: calc(100% - 300px);
  display: flex;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
  padding: 0 15px;
  width: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a {
  font-size: 13px;
  color: #fff;
  border-left: 1px solid #fff;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  font-weight: 300;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a:first-child {
  border: none;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a i {
  margin-right: 5px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a:hover {
  color: #fff;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
  width: 200px;
  background: transparent;
  padding: 0px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media h3 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  color: #fff;
  font-size: 24px;
}

.main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media a {
  color: #fff;
  margin-right: 10px;
}

.main_footer .footer_full_black .copyright_block {
  text-align: center;
  border-top: 1px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.main_footer .footer_full_black .copyright_block p {
  font-size: 10px;
  color: #c4c4c4 !important;
  margin-bottom: 0px;
  background: none !important;
  border: none;
  padding: 0px !important;
  line-height: 16px;
}

/* ******************************************main_footer_css_End**************************************** */

@media (max-width:1199px) {
  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media a {
    margin: 0 9px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media span {
    margin-top: 0;
    margin-left: 0px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
    width: 100%;
    justify-content: flex-end;
    padding-right: 0;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
    flex-wrap: wrap;
  }

  .main_footer .footer_menu_row2 ul li a,
  .main_footer .footer_menu_row .footer_menu_item ul li a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .main_footer .footer_menu_row2 ul li {
    margin-right: 0px;
    line-height: 16px;
  }

  .main_footer .footer_menu_row2 ul li a,
  .main_footer .footer_menu_row .footer_menu_item ul li a {
    padding: 0 0px;
  }

  .main_footer .footer_menu_row2 ul {
    align-items: flex-start;
  }

  .main_footer .footer_menu_row {
    padding: 10px 15px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_title {
    font-size: 20px;
    font-weight: 500;
    top: 23px;
    width: 150px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
    font-weight: 500;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row {
    align-items: center;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row {
    border: 1px solid #fff;
    padding: 20px 20px 15px 20px;
  }

  .main_footer .footer_menu_row2 ul li a,
  .main_footer .footer_menu_row .footer_menu_item ul li a {
    line-height: 14px;
  }

  .main_footer .footer_menu_row2 ul li,
  .main_footer .footer_menu_row .footer_menu_item ul li {
    line-height: 24px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details {
    margin: 10px 0px;
  }

  .main_footer .footer_full_black .get_in_touch {
    padding-top: 35px;
  }
}

@media (max-width:991px) {
  .main_header .menubar_row .logo {
    width: 140px;
  }

  .main_footer .footer_menu_row .footer_menu_item ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .main_footer .footer_menu_row2 ul {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .main_footer .footer_menu_row2 ul li {
    margin-right: 0px;
  }

  .main_footer .footer_menu_row2 ul li a,
  .main_footer .footer_menu_row .footer_menu_item ul li a {
    font-size: 12px;
    padding: 0 10px;
    border-right: 1px solid #fff;
  }

  .main_footer .footer_menu_row2 ul li:last-child a,
  .main_footer .footer_menu_row .footer_menu_item ul li:last-child a {
    border-right: none;
  }

  .main_footer .footer_menu_row2 {
    padding: 10px 0px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row {
    flex-wrap: wrap;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row,
  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social {
    width: 100%;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details,
  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
    justify-content: flex-start;
    padding-left: 0;
  }

  .main_header .header_static .main_menu_inner .main_menu {
    width: auto;
  }

  .main_header .header_static .main_menu_inner {
    width: auto;
  }

  .main_header .header_static .logo_section {
    text-align: center;
    margin: 0 auto;
  }

  .main_header .header_static {
    display: flex;
    position: relative;
    width: 100%;
    background: #fff;
    top: 0;
    padding: 7px 0px;
    left: 0px;
  }

  .main_header .header_static .main_menu_inner .main_menu nav ul {
    margin: 0px;
    padding: 0px 10px;
  }

  .main_header .header_static .main_menu_inner .main_menu #man_menu_show_hide {
    width: 100%;
    position: fixed;
    height: calc(100vh - 51px);
    background: #fff;
    left: 0px;
    max-width: 100%;
    box-shadow: none;
    border-right: none;
    top: 50px;
    z-index: 9999;
    overflow-y: auto;
  }

  .main_header .header_static .main_menu_inner .main_menu nav ul {
    display: block;
    overflow-y: auto;
  }

  .main_header .header_static .main_menu_inner .main_menu nav ul li>a {
    padding: 10px 10px 10px 0px;
    font-size: 16px;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn {
    display: block;
    position: absolute;
    left: 5px;
    background: #fff;
    -webkit-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    -moz-box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    box-shadow: 0 0 6px 1px rgba(52, 105, 203, .16);
    text-align: center;
    margin: 0px auto;
    border-radius: 8px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    top: 7px;
    z-index: 9999;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn img {
    width: 25px;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon {
    height: 0;
    background: transparent;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:before {
    top: 0 !important;
    -webkit-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn.close .main-menu-btn-icon:after {
    top: 0 !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
  }

  .main_header .header_static .main_menu_inner #menu_open_btn:hover #man_menu_show_hide {
    height: 0;
    background: transparent;
  }

  .main_header {
    background-color: #fff;
    width: 100%;
  }

  .main_header .header_static .main_menu_inner .main_menu .login_box {
    margin-left: 0;
    margin-right: 15px;
  }

  .main_header .header_static .main_menu_inner .main_menu .login_box button {
    width: auto;
    background-image: none;
    height: auto;
    color: #fff;
    border-radius: 0;
    background: #00a0e300;
    color: #000;
    margin-right: 0px;
    border: 1px solid #00a0e3;
    font-size: 14px;
    border-radius: 5px;
    padding: 1px 12px;
  }

  .main_header .header_static .main_menu_inner .main_menu nav ul li {
    display: block;
  }
}

@media (max-width:768px) {

  .main_header .menubar_row .logo,
  .main_header .menubar_row .logo img {
    width: 100px;
  }

  .mobile_menu_bottom.fixed_bottom_menu {
    display: flex;
  }

  .main_footer .footer_menu_row2 ul {
    flex-wrap: wrap;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details,
  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .social_media {
    flex-wrap: wrap;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .contact_social .contact_details a {
    padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0px;
    display: block;
    width: 100%;
    border-left: none;
  }

  .main_footer .footer_full_black .copyright_block p {
    font-size: 8px;
    margin: 0px;
    line-height: 12px;
  }

  .main_footer .footer_full_black .copyright_block {
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .mobile_menu_bottom {
    padding: 6px 15px;
  }

  .main_footer .footer_menu_row {
    padding: 10px;
  }

  .main_footer .footer_menu_row .footer_menu_item ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .main_footer .footer_menu_row2 ul li a,
  .main_footer .footer_menu_row .footer_menu_item ul li a {
    font-size: 10px;
    padding: 0 10px;
    border-right: 1px solid #fff;
  }

  .main_footer .footer_menu_row2 ul li:last-child a,
  .main_footer .footer_menu_row .footer_menu_item ul li:last-child a {
    border-right: none;
  }

  .main_footer .footer_menu_row2 ul li {
    margin-right: 0px;
    line-height: 20px;
  }

  .main_footer .footer_menu_row2 ul {
    justify-content: center;
  }
}

@media (max-width:576px) {
  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span {
    font-size: 16px;
    margin-right: 10px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row span img {
    width: 30px;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row .address_row p b {
    font-weight: 500;
  }

  .main_footer .footer_full_black .get_in_touch .get_in_touch_row {
    border: 1px solid #fff;
    padding: 18px 15px 15px 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .mobile_menu_bottom .menu__item span {
    font-size: 11px;
  }
}

.main-menu-btn {
  float: right;
  position: relative;
  display: inline-block;
  width: 20px;
  height: 25px;
  text-indent: 25px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
  position: absolute;
  top: 50%;
  left: 8px;
  height: 1px;
  width: 20px;
  background: #262525;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  z-index: 9999;
}

.main-menu-btn-icon {
  width: 14px;
}

.main-menu-btn-icon:before {
  content: '';
  top: -8px;
  left: 0;
}

.main-menu-btn-icon:after {
  content: '';
  top: 8px;
  left: 0;
}

@media (min-width: 768px) {
  .main-menu-btn {
    position: absolute;
    top: -99999px;
  }
}

@media (max-width: 767px) {
  .main-menu-btn {
    float: left;
  }
}

.d_flex {
  display: flex;
}

.flex_Equal {
  justify-content: space-between;
}

.flex_Center {
  justify-content: center;
}

.flex_Start {
  justify-content: flex-start;
}

.flex_End {
  justify-content: flex-end;
}

.flex_Middel {
  align-items: center;
}

.flex_Top {
  align-items: flex-start;
}

.flex_Bottom {
  align-items: flex-end;
}

.flex_column {
  flex-direction: column;
}

.flex_wrap {
  flex-wrap: wrap;
}

.flex_row {
  flex-direction: row;
}

.flex::after,
.flex::before {
  display: none !important;
}

.fw_100 {
  font-weight: 100;
}

.fw_200 {
  font-weight: 200;
}

.fw_300 {
  font-weight: 300;
}

.fw_400 {
  font-weight: 400;
}

.fw_500 {
  font-weight: 500;
}

.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}

.fw_800 {
  font-weight: 800;
}

.fw_900 {
  font-weight: 900;
}

.fw_normal {
  font-weight: 400;
}

.d_none {
  display: none;
}

.d_inline_block {
  display: inline-block;
}

.d_block {
  display: block !important;
}

.background_white {
  background-color: #fff;
}

.background_red {
  background-color: #005650;
}

.error {
  color: #ec1c24;
}

.border_red30px {
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}

.border_red5px {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.main_card {
  background-color: #fff;
  box-shadow: 0 0 15px 0 #0d398f26;
  position: relative;
}

.main_card_body {
  padding: 12px;
}

.main_card .icon,
.main_card .title {
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.main_card:hover .icon,
.main_card:hover .title {
  color: #0d398f;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.border_red15px {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.text_uppercase {
  text-transform: uppercase;
}

.text_capitalize {
  text-transform: capitalize;
}

.text_justfy {
  text-align: justify;
}

.box_shadwo_light {
  box-shadow: 0 0 5px 0 #1d579946;
}

.min_height {
  height: 80px;
}

.m_auto {
  margin: 0 auto;
}

.ml_auto {
  margin-left: auto;
}

.ml_20 {
  margin-left: 20px;
}

.ml_10 {
  margin-left: 10px;
}

.m_15 {
  margin: 15px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_10 {
  margin-right: 10px;
}

.ml_30 {
  margin-left: 50px;
}

.mt_7 {
  margin-top: 7px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mb_5 {
  margin-bottom: 5px !important;
}

.mx_0 {
  margin-left: 0;
  margin-right: 0;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mt_40 {
  margin-top: 40px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_0 {
  margin-bottom: 0 !important;
}

.mt_35 {
  margin-top: 35px;
}

.border_none {
  border: none !important;
}

.border_bottom {
  border-bottom: 1px solid #ddd;
}

.border_bottom1 {
  border-bottom: 1px solid #ddd;
}

.border_top1 {
  border-top: 1px solid #fff;
}

.border_top_gray1 {
  border-top: 1px solid #ddd;
}

.border_bottom_dashed {
  border-bottom: 2px dashed #fff;
}

.background_none {
  background-color: transparent !important;
}

.sliver_background {
  background: #f4feff;
}

.top_shodw {
  box-shadow: 0 -15px 20px 0 #cccccc73;
}

.clip_path_none {
  clip-path: none !important;
}

.P_15 {
  padding: 15px;
}

.p_0 {
  padding: 0;
}

.pb_15 {
  padding-bottom: 15px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pb_60 {
  padding-bottom: 60px;
}

.pb_80 {
  padding-bottom: 80px;
}

.pb_200 {
  padding-bottom: 200px;
}

.pt_7 {
  padding-top: 7px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_30 {
  padding-top: 30px;
}

.pl_25 {
  padding-left: 25px;
}

.pl_15 {
  padding-left: 15px;
}

.p_070 {
  padding: 0 60px;
}

.py_5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pr_15 {
  padding-right: 15px;
}

.mt_min_110 {
  margin-top: -110px;
}

.z_index999 {
  z-index: 999;
}

.li_width li {
  width: 25%;
  font-size: 12px;
  font-weight: 700;
}

.list_css li {
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  position: relative;
  padding-left: 20px;
}

.list_css li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 12px;
  height: 12px;
  background-color: #005650;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ul_style li {
  margin: 3px;
}

.ul_style li>div {
  padding: 5px;
  border: 1px solid #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div:hover {
  border: 1px solid #005650;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div img {
  max-width: 130px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.ul_style li>div:hover img {
  filter: grayscale(10);
  -webkit-filter: grayscale(10);
}

.edit_btn {
  border: none;
  background: #16d7c0;
  color: #fff;
  font-size: 15px;
  height: 35px;
  width: 35px;
  margin-left: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.edit_btn:hover {
  background: #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.buy_now_btn {
  border: none;
  background: #16d7c0;
  color: #fff;
  font-size: 13px;
  margin-bottom: 5px;
  height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.buy_now_btn:hover {
  background: #363636;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.fin_logo img {
  width: 150px;
}

.w_100 {
  width: 100%;
}

.w_50 {
  width: 50%;
}

.h_100 {
  height: 100%;
}

.h_50 {
  height: 50%;
}

.cursor_pointer {
  cursor: pointer;
}

.panel_active_class.click_btn small {
  color: #005650;
}

.change_icon.rotate_icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.text_bottom_border {
  position: relative;
}

.text_bottom_border::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  width: 150px;
  text-align: center;
  margin: 0 auto;
  height: 3px;
  background: #16d7c0;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.text_bottom_border_white {
  position: relative;
}

.text_bottom_border_white::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 70px;
  text-align: center;
  height: 3px;
  background-color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.text_green {
  color: #005650;
}

.text_dark {
  color: #363636;
}

.text_white {
  color: #fff !important;
}

.border_red50 {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fs_8 {
  font-size: 8px;
}

.fs_9 {
  font-size: 9px;
}

.fs_10 {
  font-size: 10px;
}

.fs_11 {
  font-size: 11px;
}

.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs_17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs_19 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs_21 {
  font-size: 21px;
}

.fs_22 {
  font-size: 22px;
}

.fs_23 {
  font-size: 23px;
}

.fs_24 {
  font-size: 24px;
}

.fs_25 {
  font-size: 25px;
}

.fs_26 {
  font-size: 26px;
}

.fs_27 {
  font-size: 27px;
}

.fs_28 {
  font-size: 28px;
}

.fs_29 {
  font-size: 29px;
}

.fs_30 {
  font-size: 30px;
}

.fs_31 {
  font-size: 31px;
}

.fs_32 {
  font-size: 32px;
}

.fs_33 {
  font-size: 33px;
}

.fs_34 {
  font-size: 34px;
}

.fs_35 {
  font-size: 35px;
}

.fs_36 {
  font-size: 36px;
}

.fs_37 {
  font-size: 37px;
}

.fs_38 {
  font-size: 38px;
}

.fs_39 {
  font-size: 39px;
}

.fs_40 {
  font-size: 40px;
}

.fs_41 {
  font-size: 41px;
}

.fs_42 {
  font-size: 42px;
}

.fs_43 {
  font-size: 43px;
}

.fs_44 {
  font-size: 44px;
}

.fs_45 {
  font-size: 45px;
}

.fs_46 {
  font-size: 46px;
}

.line_height_24 {
  line-height: 40px;
}

.line_height_34 {
  line-height: 34px;
}


.home_banner_part {
  box-shadow: 0px 15px 20px 0px #36363614;
  height: calc(100vh - 150px);
}

.home_banner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: calc(100vh - 150px);
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile_part_block .mobile_img img {
  max-width: 400px;
  width: 100%;
  margin: auto;
  text-align: center;
  display: block;
}

.home_banner_part #home_slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.home_banner_part #home_slider .slide_img_box {
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
  position: relative;
}

.home_banner_part #home_slider .slide_img_box img {
  text-align: center;
  margin: auto;
  width: 100%;
  object-fit: cover;
}

.home_banner_part .text_box {
  width: 100%;
  max-width: 300px;
  padding-left: 35px;
}

.home_banner_part #home_slider .owl-nav {
  display: none;
}

.home_banner_part .text_box .products_box {
  text-align: center;
  padding: 30px 10px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  width: 50%;
  min-height: 120px;
  position: relative;
}

.home_banner_part .main_box .text_box .products_box.br_slide1::before {
  position: absolute;
  right: 0;
  content: "";
  top: 0;
  width: 2px;
  height: 50%;
  background: #16d7c0;
  animation: top_bottom 5s infinite;
  -webkit-animation: top_bottom 5s infinite;
}

.home_banner_part .main_box .text_box .products_box.br_slide2::before {
  position: absolute;
  right: 0;
  content: "";
  bottom: 0;
  width: 50%;
  height: 2px;
  background: #363636;
  animation: right_side 5s infinite;
  -webkit-animation: right_side 5s infinite;
}

.home_banner_part .main_box .text_box .products_box.br_slide3::before {
  position: absolute;
  left: 0;
  content: "";
  top: -2px;
  width: 50%;
  height: 2px;
  background: #363636;
  animation: right_side 5s infinite;
  -webkit-animation: right_side 5s infinite;
}

.home_banner_part .main_box .text_box .products_box.br_slide4::before {
  position: absolute;
  left: -2px;
  content: "";
  bottom: 0;
  width: 2px;
  height: 50%;
  background: #005650;
  animation: bottom_top 5s infinite;
  -webkit-animation: bottom_top 5s infinite;
}

@keyframes right_side {
  from {
    width: 15%;
  }

  to {
    width: 100%;
  }
}

@keyframes left_side {
  from {
    width: 100%;
  }

  to {
    width: 15%;
  }
}

@keyframes top_bottom {
  from {
    height: 15%;
  }

  to {
    height: 100%;
  }
}

@keyframes bottom_top {
  from {
    height: 15%;
  }

  to {
    height: 100%;
  }
}

.home_banner_part .text_box .products_box a {
  display: block;
}

.our_company_back {
  position: relative;
}

.form_group {
  width: 100%;
  margin-bottom: 15px;
}

.form_group label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.form_group .form_control {
  height: 35px;
  width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #999;
  background-color: #fff;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.form_group .form_control_span {
  height: 35px;
  width: 100%;
  padding: 5px 10px;
  font-size: 14px;
  color: #747e8c;
  border: 1px solid #ddd !important;
  background-color: #ddd;
  display: block;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.form_group .form_control:focus {
  box-shadow: none !important;
  outline-color: #005650;
  outline-width: 1px !important;
}

.form_group .form_control[type="file"] {
  border: none !important;
  height: auto;
  padding: 0px;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.submit_banner img {
  width: 300px;
}

.get_form_other_filds {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding-top: 30px;
}

.faq_blocks .main_panel .panel_body.panel_body_active {
  display: block;
}

.faq_blocks .main_panel .panel_body {
  display: none;
}

.faq_blocks .list_css li {
  font-size: 14px;
}

#Our_testmonial .item span {
  background: #16d7c0;
  padding: 3px;
  width: 80px;
  height: 80px;
  position: absolute;
  top: -20px;
  margin: 0 auto;
  text-align: left;
  left: 0;
  right: 0;
}

#Our_testmonial .item span img {
  width: 75px;
}

#Our_testmonial .owl-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

#Our_testmonial .owl-nav .owl-next,
#Our_testmonial .owl-nav .owl-prev {
  margin: 0 10px;
  background: #16d7c0;
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

#Our_testmonial .owl-stage-outer {
  padding-top: 40px;
}

.qute_icon {
  right: 30px;
  opacity: 0.7;
  bottom: 23px;
}

.become_banner {
  position: relative;
  z-index: 0;
}

.become_banner .img_box_ani img {
  -webkit-animation-name: jum_animation;
  animation-name: jum_animation;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.submission {
  display: none;
}

.submission.submission_active {
  display: block;
}

@keyframes jum_animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jum_animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* ********************************************************************************* */

.name_card {
  padding-left: 45px;
  z-index: 0;
  border-right: 1px solid #f5f5f5;
}

.name_card h6 {
  margin-bottom: 51px;
  position: relative;
  top: 5px;
  font-size: 14px;
}

.card2 {
  display: none;
}

.card2.show {
  display: block;
}

input:focus,
select:focus {
  box-shadow: none !important;
  border: 1px solid #005650 !important;
  outline-width: 0 !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #005650;
}

.otp_btn,
.next-button {
  width: 100px;
  height: 36px;
  background-color: #16d7c0;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button svg {
  margin-left: 5px;
}

.otp_btn:hover,
.next-button:hover {
  background-color: #005650;
  color: #fff;
}

#progressbar {
  position: absolute;
  left: 0px;
  overflow: hidden;
  color: #16d7c0;
}

#progressbar li {
  list-style-type: none;
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 36px;
  position: relative;
}

#progressbar li:nth-child(3) {
  margin-bottom: 88px;
}

#progressbar .step0:before {
  content: "";
}

#progressbar li:before {
  width: 30px;
  height: 30px;
  display: block;
  background: #fff;
  border: 1px solid #16d7c0;
  border-radius: 50%;
  margin: auto;
}

#progressbar li:last-child:before {
  width: 40px;
  height: 40px;
}

#progressbar li:after {
  content: "";
  width: 3px;
  height: 66px;
  position: absolute;
  left: 58px;
  top: 15px;
  z-index: -1;
}

#progressbar li:last-child:after {
  top: 147px;
  height: 132px;
}

#progressbar li:nth-child(3):after {
  top: 81px;
}

#progressbar li:nth-child(2):after {
  top: 0px;
}

#progressbar li:first-child:after {
  position: absolute;
  top: -81px;
}

#progressbar li.active:after {
  background: #16d7c0;
}

#progressbar li.active .check_icon {
  color: #fff !important;
}

#progressbar li.active::before {
  background-color: #4bb543;
  content: "";
}

#progressbar li .check_icon {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0px;
  right: 0;
  text-align: center;
  margin: auto;
  z-index: 8888888;
  color: #16d7c0;
  font-size: 16px;
}

/* *****************************************home_page_css***************************** */

.moter_form_details {
  margin-bottom: 45px;
}

.home_page_css {
  height: auto;
}

.home_page_css .whyHeadTxt {
  font-size: 28px;
  line-height: 34px;
  color: #005650;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 700;
  padding-top: 45px;
}

.home_page_css .main-container {
  margin: 0 auto;
  max-width: 906px;
  padding: 2rem 1rem;
}

.home_page_css .grid-wrapper {
  --auto-grid-min-size: 7rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill,
      minmax(var(--auto-grid-min-size), 1fr));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.home_page_css .grid-wrapper li {
  background-color: white;
  color: #ffffff;
  font-size: 24px;
  list-style-type: none;
  padding: 1rem 0rem;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 600;
  transition: transform 0.2s;
}

.home_page_css .grid-wrapper li div img {
  width: 40%;
  object-fit: contain;
}

.home_page_css .prodEleName {
  font-size: 13px;
  line-height: 16px;
  color: #080808;
  text-align: center;
  margin-top: 10px;
}

.home_page_css .grid-wrapper2 {
  --auto-grid-min-size: 6rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill,
      minmax(var(--auto-grid-min-size), 1fr));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.home_page_css .grid-wrapper2 li {
  background-color: white;
  color: #ffffff;
  font-size: 24px;
  list-style-type: none;
  padding: 1rem 0rem;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 600;
  transition: transform 0.2s;
}

.home_page_css .grid-wrapper2 li:hover {
  transform: scale(1.1);
}

.home_page_css .main-container2 {
  margin: 0 auto;
  max-width: 700px;
  padding: 2rem 1rem;
}

.home_page_css .grid-wrapper3 {
  --auto-grid-min-size: 7rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill,
      minmax(var(--auto-grid-min-size), 1fr));
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.home_page_css .grid-wrapper3 li {
  background-color: white;
  color: #ffffff;
  font-size: 24px;
  list-style-type: none;
  padding: 1rem 0rem;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  font-weight: 600;
  transition: transform 0.2s;
}

.home_page_css .grid-wrapper3 li:hover {
  transform: scale(1.1);
}

.home_page_css .whyEleSvg {
  display: inline;
  margin-bottom: 10px !important;
  margin-top: 14px !important;
}

.home_page_css .whyEleTxt {
  margin-bottom: 10px !important;
  margin-top: 14px !important;
}

.home_page_css .collapsible {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  background: #ededed;
  text-align: center;
}

.home_page_css .bld {
  font-weight: 400;
  color: #363232;
  text-align: center;
  font-size: 18px;
}

.home_page_css .collapsible p {
  font-size: 14px;
  margin: 0px;
}

.whyHeadSubTxt {
  font-size: 14px;
}

.home_page_css .content {
  height: 0;
  overflow: hidden;

  /* height: auto;
    overflow: visible; */

  transition: height 0.3s;
}

#home {
  background-color: #005650;
}

.home_page_css .contentView {
  height: auto;
  overflow: visible;
  transition: height 0.3s;
}

#team {
  text-align: center;
  margin-top: 55px;
}

#team .whyHeadSubTxt {
  margin-bottom: 35px;
}

.home_page_css .disclaimerSecWrap {
  color: #fff;
}

.home_page_css .footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 15px;
}

.home_page_css .footer-links a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #f1f1f1;
}

.home_page_css .line-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.home_page_css .line {
  flex-grow: 1;
  border-bottom: 1px solid #ffffff;
}

.home_page_css .footer-heading {
  font-size: 20px;
  padding: 0 20px;
  font-weight: bold;
  color: white;
}

.home_page_css .line {
  flex-grow: 1;
  border-bottom: 1px solid #ffffff;
}

.home_page_css .footer-links li {
  display: inline-block;
  margin: 0;
}

.home_page_css .heroRight {
  text-align: center;
  padding-bottom: 45px;
}

.home_page_css .whyListWrap {
  text-align: left;
}

.whyList {
  display: flex;
  margin-bottom: 30px;
}

.whyList .whyEleSvg {
  width: 45px;
}

.whyList .whyEleTxtWrap {
  width: calc(100% - 45px);
  padding-left: 20px;
}

.whyList .whyEleTxtWrap .whyEleTxt {
  font-size: 16px;
  font-weight: 600;
  margin: 0px !important;
}

.whyList .whyEleTxtWrap .whyHeadSubTxt1 {
  font-size: 14px;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 130px;
  overflow-x: hidden;
  marquee-direction: reverse;
}

.marquee2 {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 130px;
  overflow-x: hidden;
  marquee-direction: reverse;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
  -webkit-animation: marquee 15s linear infinite;
}

.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite reverse;
  -webkit-animation: marquee 15s linear infinite reverse;
}

.marquee .track img,
.marquee2 .track2 img {
  border: 2px solid #59cad9;
  margin: 10px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
}

/* *****************************************home_page_css***************************** */

/* ********************************************payment_page_css_start***************************************** */

.payment_page_css .payment_block {
  display: block;
  overflow: inherit;
  padding: 40px 10px 10px 10px;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #0d398f54;
  border-radius: 5px;
}

.payment_page_css h1 {
  color: #005650;
}

.payment_page_css .payment_block li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  padding: 8px 5px;
  min-width: 100%;
  box-shadow: none;
  border-bottom: 1px dashed #d6d6d6;
  font-size: 12px;
  font-weight: 500;
}

.payment_page_css .payment_block li.last_child {
  border: none;
  padding: 0;
}

.payment_page_css .payment_block li label {
  font-weight: 700;
  color: #005650;
}

.payment_page_css .payment_block .payment_note p span {
  font-weight: 700;
}

.payment_page_css .payment_block .payment_note p {
  padding: 5px;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 0;
  background: #ffd3d3;
  color: black;
  border-radius: 5px;
}

.payment_page_css .payment_block .download_policy {
  position: absolute;
  float: inherit !important;
  padding: 5px 24px;
  background: #04cb64;
  margin: 0;
  color: white;
  border: 0;
  border-radius: 100px 0px 0px 100px;
  right: -1px;
  top: 0;
  cursor: pointer;
}

.payment_page_css .payment_img_box {
  text-align: center;
}

.payment_page_css .payment_img_box img {
  max-width: 450px;
  width: 100%;
}

@media (max-width: 767px) {
  .payment_page_css .py_order_2 {
    order: 2;
  }

  .payment_page_css .py_order_1 {
    order: 1;
  }
}

.starcolor::after {
  content: " *";
  color: red;
}

/* ********************************************payment_page_css_end***************************************** */

/* *********************************************************************************************** */


@media (max-width: 1156px) {
  .home_banner_part #home_slider .slide_img_box img {
    text-align: center;
    margin: auto;
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 150px);
  }
}

@media (max-width: 992px) {
  .home_banner.d_flex {
    display: block !important;
  }

  .home_banner_part .text_box {
    max-width: 100%;
    min-height: auto;
  }

  .home_banner_part #home_slider {
    max-width: calc(100% - 0px);
  }

  .left_design .col-lg-6.col-md-12.pt_60 {
    padding-top: 0;
  }


  .about_content {
    margin-top: 50px;
  }

  .ul_style li>div img {
    max-width: 90px;
  }
}

@media (max-width: 768px) {
  .mobile_margin_btn {
    margin: 20px 0px;
  }

  .order_1 {
    order: 1;
  }

  .order_2 {
    order: 2;
  }

  .ul_style li>div img {
    max-width: 80px;
  }
}

@media (max-width: 576px) {
  .home_banner_part #home_slider {
    display: none;
  }

  .home_banner {
    width: 100%;
  }

  .fs_24 {
    font-size: 18px;
  }

  .fs_34 {
    font-size: 24px;
  }

  .home_banner_part .text_box {
    padding-left: 0px;
    margin: auto;
  }

  .app_btn {
    margin-bottom: 10px;
  }

  .main_popup .main_popup_wrapper {
    max-width: 280px;
  }

  .ul_style li>div img {
    max-width: 70px;
  }

  .home_banner_part {
    box-shadow: 0px 15px 20px 0px #36363614;
    height: 450px;
  }

  .home_banner {
    top: 20px;
    height: 450px;
  }

  .home_banner .fs_15.mb_10 {
    font-weight: 700;
  }
}


@media (max-width: 1156px) {
  .home_banner_part #home_slider .slide_img_box img {
    text-align: center;
    margin: auto;
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 150px);
  }
}

@media (max-width: 992px) {
  .home_banner.d_flex {
    display: block !important;
  }

  .home_banner_part .text_box {
    max-width: 100%;
    min-height: auto;
  }

  .home_banner_part #home_slider {
    max-width: calc(100% - 0px);
  }

  .left_design .col-lg-6.col-md-12.pt_60 {
    padding-top: 0;
  }

  .about_content {
    margin-top: 50px;
  }

  .ul_style li>div img {
    max-width: 90px;
  }
}

@media (max-width: 768px) {
  .order_1 {
    order: 1;
  }

  .order_2 {
    order: 2;
  }

  .ul_style li>div img {
    max-width: 80px;
  }
}

@media (max-width: 576px) {
  .home_banner_part #home_slider {
    display: none;
  }

  .home_banner {
    width: 100%;
  }

  .fs_24 {
    font-size: 18px;
  }

  .fs_34 {
    font-size: 24px;
  }

  .home_banner_part .text_box {
    padding-left: 0px;
    margin: auto;
  }

  .app_btn {
    margin-bottom: 10px;
  }


  .main_popup .main_popup_wrapper {
    max-width: 280px;
  }

  .ul_style li>div img {
    max-width: 70px;
  }

  .home_banner_part {
    box-shadow: 0px 15px 20px 0px #36363614;
    height: 450px;
  }

  .home_banner {
    top: 20px;
    height: 450px;
  }

  .home_banner .fs_15.mb_10 {
    font-weight: 700;
  }
  .main_custom_popup .main_custom_popup_inner .main_custom_popup_inner_body .login_popup_data .login_details {
    flex-wrap: wrap;
  }
}

.model_headers {
  border-bottom: 0 none;
}

span.error-msg {
  color: red;
  font-size: 12px;
}

.errorBorder {
  border-color: red !important;
}

.error_data_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px dashed #ddd;
  padding: 5px;
  margin-bottom: 10px;
}

.disableTopButton {
  background: unset;
}

button:disabled {
  cursor: not-allowed;
}